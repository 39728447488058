<template>
  <v-container>
    <br>
    <!-- Important Dates and All/None buttons on the same row -->
    <v-row justify="space-between" align="center" class="mb-3">
      <!-- All/None buttons -->
      <v-col class="d-flex justify-end">
        <v-btn @click="openAll" class="mx-2">All</v-btn>
        <v-btn @click="closeAll" class="mx-2">None</v-btn>
      </v-col>
      <br><br>
    </v-row>

    <!-- Expansion Panels -->
    <v-expansion-panels v-model="expandedPanels" multiple>
      <ExpansionPanel
        v-for="(section, index) in sections"
        :key="index"
        :title="section.title"
        :items="section.items"
        :value="index"
      />
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ExpansionPanel from './ExpansionPanel.vue'; // ���� ���� ������Ʈ

export default {
  components: {
    ExpansionPanel,
  },
  data() {
    return {
      expandedPanels: [], // ���� Ȯ��� �г��� �����ϴ� �迭
      sections: [
        {
          title: 'Registration',
          items: [
            { content: 'Registration portal opens', date: '2025.00.00.' },
            { content: 'Author registration deadline', date: '2025.00.00.' },
            { content: 'Early Bird registration deadline', date: '2025.00.00.' },
            { content: 'Online registration portal closes for in-person attendance', date: '2025.00.00.' },
            { content: 'Registration deadline for virtual attendance', date: '2025.00.00.' },
            { content: 'Registration at the venue (for in-person attendance)', date: '2025.00.00.' }
          ],
        },
        {
          title: 'Grants',
          items: [
            { content: 'Grant applications for registration waivers open', date: '2025.00.00.' },
            { content: 'Application deadline for Author grants', date: '2025.00.00.' },
            { content: 'Author grant notifications', date: '2025.00.00.' },
            { content: 'Application deadline for Music Author grants and WiMIR grants', date: '2025.00.00.' },
            { content: 'Grant notifications for Music Author grants and WiMIR grants', date: '2025.00.00.' }
          ],
        },
        {
          title: 'Papers',
          items: [
            { content: 'Submission site opening', date: '2025.00.00.' },
            { content: 'Abstract submission deadline', date: '2025.00.00.' },
            { content: 'Full paper submission deadline', date: '2025.00.00.' },
            { content: 'Notification of Acceptance', date: '2025.00.00.' },
            { content: 'Camera ready deadline', date: '2025.00.00.' }
          ],
        },
        {
          title: 'Music',
          items: [
            { content: 'First Call for Music', date: '2025.00.00.' },
            { content: 'Submission site opening', date: '2025.00.00.' },
            { content: 'Music submission deadline', date: '2025.00.00.' },
            { content: 'Notification of acceptance', date: '2025.00.00.' },
            { content: 'Performance-ready submission deadline', date: '2025.00.00.' }
          ],
        },
        {
          title: 'Tutorials',
          items: [
            { content: 'Proposal Submission', date: '2025.00.00.' },
            { content: 'Revision', date: '2025.00.00.' },
            { content: 'Notification of Acceptance', date: '2025.00.00.' }
          ],
        },
        {
          title: 'LBD',
          items: [
            { content: 'LBD Submission Deadline', date: '2025.00.00.' }
          ],
        },
        {
          title: 'Mentoring',
          items: [
            { content: 'Deadline for applying to mentorship program', date: '2025.00.00.' },
            { content: 'Deadline for mentees for paper submission', date: '2025.00.00.' },
            { content: 'Notification for Mentor-Mentee match', date: '2025.00.00.' },
            { content: 'Feedback from mentors', date: '2025.00.00.' }
          ],
        },
      ],
    };
  },
  methods: {
    openAll() {
      this.expandedPanels = this.sections.map((_, index) => index); // ��� �г��� ����
    },
    closeAll() {
      this.expandedPanels = []; // ��� �г��� �ݱ�
    },
  },
  mounted() {
    this.openAll(); // �������� �����ڸ��� ��� �г� ����
  },
};
</script>
