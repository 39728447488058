<template>
  <!-- AppBar -->
  <v-app-bar
    id="home-app-bar"
    :elevation="isMobile ? 0 : 1"
    :height="isMobile ? '56' : '80'"
    scroll-behavior="elevate"
  >
    <template #prepend>
      <div :style="{
    marginLeft: isMobile ? '5px' : '30px',
    marginBottom: isMobile ? '0' : '10px'
  }">
    <v-img
      :src="LogoImg"
      class="logo-clickable"
      width="160"
      contain
      @click="navigateHome"
    ></v-img>
  </div>
    </template>

    <v-spacer />

    <!-- Main Menu Buttons (Desktop Only) -->
    <div class="menu-container hidden-sm-and-down">
      <v-btn
        exact
        ripple="false"
        :to="{ name: 'HomePage' }"
        class="font-weight-bold menu-button"
        height="100%"
      >
        Home
      </v-btn>
      <!-- 
      //Program Menu with Drop-down
      <v-menu offset-y v-model="isProgramMenuOpen" close-on-content-click>
        <template v-slot:activator="{ props }">
          <v-btn
            class="font-weight-bold menu-button call-button"
            :class="{ 'call-active': isProgramMenuOpen }"
            v-bind="props"
            height="100%"
            variant="plain"
            text-color="black"
          >
            Program
            <v-icon icon="mdi-menu-down" end></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(submenu, index) in programSubItems"
            :key="index"
            @click="handleSubItemClickProgram(submenu)"
          >
            <v-list-item-title>{{ submenu }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    -->
      <!-- 
    //Call Menu with Drop-down
      <v-menu offset-y v-model="isCallMenuOpen" close-on-content-click>
        <template v-slot:activator="{ props }">
          <v-btn
            class="font-weight-bold menu-button call-button"
            :class="{ 'call-active': isCallMenuOpen }"
            v-bind="props"
            height="100%"
            variant="plain"
            text-color="black"
          >
            Call
            <v-icon icon="mdi-menu-down" end></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(submenu, index) in callSubItems"
            :key="index"
            @click="handleSubItemClickCall(submenu)"
          >
            <v-list-item-title>{{ submenu }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
       -->
      <!--
      <v-btn
        ripple="false"
        :to="{ name: 'ImportantDatesPage' }"
        class="font-weight-bold menu-button"
        height="100%"
      >
        Important Dates
      </v-btn>
      -->
      <!--
      // Registration �޴� �߰� 
      <v-btn
        ripple="false"
        href="https://mac.kaist.ac.kr/"
        target="_blank"
        class="font-weight-bold menu-button"
        height="100%"
      >
        Registration
      </v-btn>
      -->
      <!--
      // Attend Menu with Drop-down
      <v-menu offset-y v-model="isAttendMenuOpen" close-on-content-click>
        <template v-slot:activator="{ props }">
          <v-btn
            class="font-weight-bold menu-button call-button"
            :class="{ 'call-active': isAttendMenuOpen }"
            v-bind="props"
            height="100%"
            variant="plain"
            text-color="black"
          >
            Attend
            <v-icon icon="mdi-menu-down" end></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(submenu, index) in attendSubItems"
            :key="index"
            @click="handleSubItemClickAttend(submenu)"
          >
            <v-list-item-title>{{ submenu }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    -->
      <v-btn
        ripple="false"
        :to="{ name: 'OrganizingCommitteePage' }"
        class="font-weight-bold menu-button"
        height="100%"
      >
        Organizing Committee
      </v-btn>

      <!--
      //Diversity and Inclusion Menu with Drop-down 
      <v-menu offset-y v-model="isDiversityMenuOpen" close-on-content-click>
        <template v-slot:activator="{ props }">
          <v-btn
            class="font-weight-bold menu-button call-button"
            :class="{ 'call-active': isDiversityMenuOpen }"
            v-bind="props"
            height="100%"
            variant="plain"
            text-color="black"
          >
            Diversity and Inclusion
            <v-icon icon="mdi-menu-down" end></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(submenu, index) in diversitySubItems"
            :key="index"
            @click="handleSubItemClickDiversity(submenu)"
          >
            <v-list-item-title>{{ submenu }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      -->
      <v-btn
        ripple="false"
        :to="{ name: 'ContactPage' }"
        class="font-weight-bold menu-button"
        height="100%"
      >
        Contact
      </v-btn>
    </div>

    <!-- Hamburger Icon for Mobile -->
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
  </v-app-bar>

<!-- Slide Drawer -->
<v-navigation-drawer
  v-model="drawer"
  app
  temporary
  left
  scrollable
>
  <v-list>
    <v-list-item
      v-for="(item, index) in menuItems"
      :key="index"
      @click="handleItemClick(item)"
    >
      <v-list-item-title>{{ item.label }}</v-list-item-title>
    </v-list-item>
  </v-list>
</v-navigation-drawer>


</template>

<script setup>
import LogoImg from '@/assets/psuedoLogo.png'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'

const router = useRouter()
const { mdAndDown } = useDisplay()

// Drawer ����
const drawer = ref(false)
/*
// Call �޴��� ���� ������
const callSubItems = ref([
  'Call For Papers',
  //'Call For Late-Breaking Demo',
  //'Call For Tutorials'
])


// Attend �޴��� ���� ������
const attendSubItems = ref([
  'Venue',
  'Travel',
  'Accommodation'
])


// Attend �޴��� ���� ������
const programSubItems = ref([
  'Program at Glance',
  'Keynote Presentations',
  'Accepted Papers',
  'Tutorials',
  'Satelite Events'
])

// Attend �޴��� ���� ������
const diversitySubItems = ref([
  'Mentoring'
])
*/

// Main �޴� �׸�
const menuItems = ref([
  { label: 'Home', route: { name: 'HomePage' } },
  //{ label: 'Program at Glance', route: { name: 'ProgramAtGlancePage' } },
  //{ label: 'Keynote Presentations', route: { name: 'KeynotePresentationsPage' } },
  //{ label: 'Accepted Papers', route: { name: 'AcceptedPapersPage' } },
  //{ label: 'Tutorials', route: { name: 'TutorialsPage' } },
  //{ label: 'Satelite Events', route: { name: 'SateliteEventsPage' } },
  //{ label: 'Call For Papers', route: { name: 'CallForPapersPage' } },
  //{ label: 'Call For Late-Breaking Demo', route: { name: 'CallForLateBreakingDemo' } },
  //{ label: 'Call For Tutorials', route: { name: 'CallForTutorialsPage' } },
  //{ label: 'Important Dates', route: { name: 'ImportantDatesPage' } },
  //{ label: 'Venue', route: { name: 'VenuePage' } },
  //{ label: 'Travel', route: { name: 'TravelPage' } },
  //{ label: 'Accommodation', route: { name: 'AccommodationPage' } },
  { label: 'Organizing Committee', route: { name: 'OrganizingCommitteePage' } },
  //{ label: 'Mentoring', route: { name: 'DiversityMentoringPage' } },
  { label: 'Contact', route: { name: 'ContactPage' } }
])

// ����� ���θ� Ȯ���ϴ� ��� �Ӽ�
const isMobile = computed(() => mdAndDown.value)

// Track if Call menu is open
//const isCallMenuOpen = ref(false)
//const isProgramMenuOpen = ref(false)
//const isAttendMenuOpen = ref(false)
//const isDiversityMenuOpen = ref(false)


// Navigate to Home page
const navigateHome = () => {
  router.push({ name: 'HomePage' })
}
/*
const handleSubItemClickCall = (submenu) => {
  switch (submenu) {
    case 'Call For Papers':
      router.push({ name: 'CallForPapersPage' })
      break
    case 'Call For Late-Breaking Demo':
      router.push({ name: 'CallForLateBreakingDemo' })
      break
    case 'Call For Tutorials':
      router.push({ name: 'CallForTutorialsPage' })
      break
    default:
      break
  }
}


const handleSubItemClickProgram = (submenu) => {
  switch (submenu) {
    case 'Program at Glance':
      router.push({ name: 'ProgramAtGlancePage' })
      break
    case 'Keynote Presentations':
      router.push({ name: 'KeynotePresentationsPage' })
      break
    case 'Accepted Papers':
      router.push({ name: 'AcceptedPapersPage' })
      break
    case 'Tutorials':
      router.push({ name: 'TutorialsPage' })
      break
    case 'Satelite Events':
      router.push({ name: 'SateliteEventsPage' })
      break
    default:
      break
  }
}


const handleSubItemClickAttend = (submenu) => {
  switch (submenu) {
    case 'Venue':
      router.push({ name: 'VenuePage' })
      break
    case 'Travel':
      router.push({ name: 'TravelPage' })
      break
    case 'Accommodation':
      router.push({ name: 'AccommodationPage' })
      break
    default:
      break
  }
}

const handleSubItemClickDiversity = (submenu) => {
  switch (submenu) {
    case 'Mentoring':
      router.push({ name: 'DiversityMentoringPage' })
      break
    default:
      break
  }
}
*/

// Drawer ������ Ŭ�� �ڵ鷯
const handleItemClick = (item) => {
  router.push(item.route)
  drawer.value = false  // �޴��� Ŭ���� �� ��ξ� �ݱ�
}
</script>

<style lang="sass">
.menu-button {
  color: black;
  font-weight: bold;
  position: relative;
  height: 100%;
  line-height: 80px; /* ����ũž ���� */
  margin: 0;
  padding: 0 16px;
}

.call-button {
  color: black !important;
  opacity: 1 !important;

  &.call-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: black;
  }

  &:hover,
  &:active {
    color: grey;
  }
}

.logo-clickable {
  cursor: pointer;
}

@media (max-width: 600px) {
  .menu-button {
    font-size: 14px;  /* ���� ȭ�鿡�� ��Ʈ ũ�⸦ ���� */
    line-height: 56px; /* ����� ���� */
  }

  .call-button {
    font-size: 14px;
  }
}
</style>
