<template>
  <v-row justify="center" id="map">
    <v-col cols="12" md="8">
  <BaseSection
    id="map"
    space="0"
  >
    <iframe
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.5306090056765!2d127.35781507636568!3d36.37214699171119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35654bb616ae884f%3A0x9fa607e06759a2c9!2zS0FJU1QgfCDtlZzqta3qs7ztlZnquLDsiKDsm5Ag67O47JuQ!5e0!3m2!1sko!2skr!4v1729582447262!5m2!1sko!2skr"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
      allowfullscreen
    />
  </BaseSection>
  <br><br>
  </v-col>
  </v-row>
</template>

<script setup>
import { provide} from 'vue'; // Vue Composition API functions
import BaseSection from '@/components/base/BaseSection.vue'

  provide('heading', { align: 'center' })
</script>
