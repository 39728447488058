<template>
  <v-row justify="center" id="call-for-papers">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Getting Here</v-card-title>
        <v-card-text class="text-body">
          A nonstop Airport Limousine bus is recommended.(from Incheon Airport to Daejeon Jeongbu Cheongsa)
        </v-card-text>
        <br>
        <v-divider></v-divider>
        <br>
        <v-card-title class="text-h5" style="color: grey;">From Terminal 1</v-card-title>
        <br>
        <ul class="number-list">
          <li>After collecting your luggage, make an exit from Gate 9D or 11E.</li>
          <li>Find a ticket stall for bus tickets. Purchase a ticket to the Daejeon Government Complex (Jeong-bu Cheong-sa). (Fare: KRW 23,700/ Night Fare: KRW 26,100)</li>
          <li>Go to Bus Stop 11 and get on the bus. Check the bus time on your ticket to board the right bus.</li>
          <li>Get off the bus at the Daejeon Government Complex (Jeong-bu Cheong-sa). The trip will take about 3 hours and 20 minutes.</li>
          <li>From there, take a taxi to the KAIST main campus. It will cost about KRW 5,000.</li>
        </ul>
        <br>
        <v-divider></v-divider>
        
        <br>
        <v-card-title class="text-h5" style="color: grey;">From Terminal 2</v-card-title>
        <br>
        <ul class="number-list">
          <li>After collecting your luggage, go to the transit center on B1.</li>
          <li>Find a bus ticket stall and purchase a ticket to the Daejeon Government Complex (Jeong-bu Cheong-sa). (Fare: KRW 23,700/ Night Fare: KRW 26,100)</li>
          <li>Go to Bus Stop 5 and get on the bus. Check the bus time on your ticket to board the right bus.</li>
          <li>Get off at the Daejeon Government Complex (Jeong-bu Cheong-sa). The trip will take about 3 hours and 40 minutes.</li>
          <li>From there, take a taxi to the KAIST main campus. It will cost about KRW 5,000.</li>
        </ul>
        <br><br>
      </v-card>

    </v-col>
  </v-row>
</template>

<script setup>
</script>

<style scoped>
.number-list {
  list-style-type: decimal; /* ���� �۸Ӹ� ��ȣ ��Ÿ�� ���� */
  font-size: 18px;
  padding-left: 40px;
  line-height: 1.8;
}
.text-body {
  font-size: 18px; /* �ؽ�Ʈ ��Ʈ ũ�� ���� */
  line-height: 1.8; /* �������� ���� �� ���� ���� */
}
</style>
