<template>
  <section id="hero-alt" class="position-relative">
    <v-img
      :height="mdAndUp ? 350 : 225"
      :src="imagePath"
      max-width="100%"
      cover
      class="with-gradient-overlay"
    >
      <v-row
        align="center"
        class="ma-0 fill-height text-center text-white"
        justify="center"
      >
        <v-col cols="12">
          <BaseHeading
            :title="currentTitle?.title || ''"
            align="center"
            weight="medium"
          />

          <BaseDivider align="center" />

          <v-breadcrumbs :items="items" style="justify-content: center;">
            <template #divider>
              <v-icon icon="mdi-chevron-right" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-img>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, provide } from 'vue'; // Vue Composition API functions
import { useDisplay } from 'vuetify'; // Vuetify hooks
import { useRoute } from 'vue-router'; // Vue Router hook
import img from '@/assets/CoverImages/k1.jpg'; // Import the image
import BaseHeading from '@/components/base/BaseHeading.vue'
import BaseDivider from '@/components/base/BaseDivider.vue'
provide('heading', { align: 'center' });

const { mdAndUp } = useDisplay();
const { path } = useRoute();

const currentTitle = ref({ path: '', title: 'HOME', src: 'k1.jpg' });
const imagePath = img; // Use the imported image

const titleList = ref([
  { path: '', title: 'Home', src: 'k1.jpg' },
  { path: 'organizing-committee', title: 'Organizing Committee', src: 'k1.jpg' },
  { path: 'program-at-glance', title: 'Program at Glance', src: 'k1.jpg' },
  { path: 'keynote-presentations', title: 'Keynote Presentations', src: 'k1.jpg' },
  { path: 'accepted-papers', title: 'Accepted Papers', src: 'k1.jpg' },
  { path: 'tutorials', title: 'Tutorials', src: 'k1.jpg' },
  { path: 'satelite-events', title: 'Satelite Events', src: 'k1.jpg' },

  { path: 'call-for-papers', title: 'Call For Papers', src: 'k1.jpg' },
  { path: 'call-for-late-breaking-demo', title: 'Call For Late-Breaking Demo', src: 'k1.jpg' },
  { path: 'call-for-tutorials', title: 'Call For Tutorials', src: 'k1.jpg' },
  { path: 'venue-page', title: 'Venue', src: 'k1.jpg' },
  { path: 'travel-page', title: 'Travel', src: 'k1.jpg' },
  { path: 'accommodation-page', title: 'Accommodation', src: 'k1.jpg' },
  { path: 'mentoring', title: 'Mentoring', src: 'k1.jpg' },
  { path: 'contact', title: 'Contact Us', src: 'k1.jpg' },
  { path: 'important-dates', title: 'Important Dates', src: 'k1.jpg' },
]);

onMounted(async () => {
  if (path.includes('/news/')) currentTitle.value = titleList.value[7];
  else if (path.includes('/gallery/')) currentTitle.value = titleList.value[8];
  else {
    const pathName = path.replaceAll('/', '');
    currentTitle.value = await titleList.value.find(val => val.path === pathName);
  }
});

const items = computed(() => {
  return [
    {
      title: 'HOME',
      disabled: false,
      href: '/',
    },
    {
      title: currentTitle.value?.title,
      disabled: false,
      href: '#',
    },
  ];
});
</script>

<style lang="sass">
#hero-alt {
  .v-breadcrumbs__item {
    color: #FFFFFF;
  }
}

.with-gradient-overlay {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(11, 28, 61, 0.9), rgba(0, 0, 0, 0.4));
    z-index: 1; /* Ensure the gradient overlay is above the image */
  }
  .v-row {
    position: relative;
    z-index: 2; /* Ensure the content is above the gradient overlay */
  }
}
</style>
