<template>
  <v-row justify="center" id="call-for-papers">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Hotel Onoma (Marriott)</v-card-title>
        <v-card-text class="text-body">
          <a href="https://www.marriott.com/en-us/hotels/cjjak-hotel-onoma-daejeon-autograph-collection/overview/" target="_blank" rel="noopener noreferrer">Website & Reservation</a>
          <br>5-star hotel
          <br>9 min on foot to the Daejeon Convention Center
        </v-card-text>
        <br>
        <v-divider></v-divider>

      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">LOTTE City Hotel Daejeon</v-card-title>
        <v-card-text class="text-body">
          <a href="https://www.lottehotel.com/daejeon-city/en.html" target="_blank" rel="noopener noreferrer">Website & Reservation</a>
          <br><a href="https://www.google.com/maps/place/%EB%A1%AF%EB%8D%B0%EC%8B%9C%ED%8B%B0%ED%98%B8%ED%85%94+%EB%8C%80%EC%A0%84/data=!4m9!3m8!1s0x3565498f89fcbd2d:0x64f2443e9a3cec0b!5m2!4m1!1i2!8m2!3d36.3759913!4d127.3928884!16s%2Fg%2F11b635dg6l?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">Directions</a>
          <br>4-star hotel
          <br>3 min on foot to the Daejeon Convention Center
          <br>Complete the reservation form at the link below and send it to the following email address: rsv.city.daejeon@lotte.net.
        </v-card-text>
        <br>
        <v-divider></v-divider>
  
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Hotel ICC</v-card-title>
        <v-card-text class="text-body">
          <a href="http://hotel.hotelicc.com/view/index.do?SS_SVC_LANG_CODE=ENG" target="_blank" rel="noopener noreferrer">Website & Reservation</a>
          <br><a href="https://www.google.com/maps/place/%ED%98%B8%ED%85%94ICC/data=!4m9!3m8!1s0x3565498fe8b3e435:0x4601bcf4eb4f2181!5m2!4m1!1i2!8m2!3d36.3769613!4d127.3926565!16s%2Fg%2F1q5br7dpq?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">Directions</a>
          <br>3-star hotel
          <br>5 min on foot to the Daejeon Convention Center
          <br>Complete the reservation form at the link below and send it to the following email address: hotelicc@naver.com.
        </v-card-text>
        <br>
        <v-divider></v-divider>

      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">I-Hotel</v-card-title>
        <v-card-text class="text-body">
          <a href="https://i-hotel.co.kr/bbs/content.php?co_id=reservation" target="_blank" rel="noopener noreferrer">Website & Reservation</a>
          <br>3 min on foot to the Daejeon Convention Center
          <br>In the reservation form, kindly include in the "Additional Requests" section that you will be attending Pacific Graphics at the Daejeon Convention Center (DCC).
        </v-card-text>
        <br>
        <v-divider></v-divider>

      </v-card>

    </v-col>
  </v-row>
</template>

<script setup>
</script>

<style scoped>
.number-list {
  list-style-type: decimal; /* ���� �۸Ӹ� ��ȣ ��Ÿ�� ���� */
  font-size: 18px;
  padding-left: 40px;
  line-height: 1.8;
}
.text-body {
  font-size: 18px; /* �ؽ�Ʈ ��Ʈ ũ�� ���� */
  line-height: 1.8; /* �������� ���� �� ���� ���� */
}

a {
  color: #1976d2; /* �����۸�ũ �÷� (�Ķ���) */
  text-decoration: none; /* ���� ���� */
}

a:hover {
  color: #1565c0; /* ���콺�� �÷��� ���� �÷� (���� �Ķ���) */
  text-decoration: underline; /* ���콺�� �÷��� �� ���� �߰� */
}
</style>
