<template>
  <v-row justify="center" id="call-for-papers">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Updates and Clarifications</v-card-title>
        <ul class="dot-list">
          <li>All deadlines are at 11:59PM Anywhere on Earth (AOE, UTC-12)</li>
          <li>An appendix is not allowed in the PDF submission, and submission of additional PDF files as supplementary material is discouraged</li>
          <li>You will not be able to modify any CMT metadata (title, authors, abstract, topics, etc.) after the abstract deadline. You may make minor changes to the abstract in the PDF submission. See additional details in "Submission Procedure" below:</li>
        </ul>
        <br>
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Important Dates</v-card-title>
        <!-- Expansion Panels -->

        <ImportantDateTable
          v-for="(section, index) in sections"
          :key="index"
          :items="section.items"
          :value="index"
        />
   
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Overview and Topics</v-card-title> <!-- ���� �ϴ� ���� �߰� -->
        <v-card-text class="text-body">
          The 25th International Society for Music Information Retrieval Conference (ISMIR 2024) will be held in San Francisco, California and online from November 10th through 14th, 2024. https://ismir2024.ismir.net 
          <br><br>
        ISMIR 2024 welcomes contributions in all the areas related to MIR and its applications, including computational music analysis, processing, generation, algorithms, and their evaluation. ISMIR is a truly interdisciplinary community, which fosters collaboration between researchers, developers, educators, librarians, students, and professionals from the disciplines involved in Music Information Retrieval, such as musicology, cognitive science, library and information science, computer science, electrical engineering, and many others. ISMIR 2024 will foster the discussion and exchange of ideas among the attendees, with special attention to new topics, emerging problems, inclusion, and diversity.
        <br><br>
        The 25th edition of the ISMIR conference carries a special theme: Bridging Technology and Musical Creativity. With this, we aim to emphasize connections across core aspects of the community that might otherwise be considered in isolation or even overlooked. We thrive by inspiring and learning from each other, while remaining humble and acknowledging the current limitations and ethical challenges around bridging the technical innovations of the MIR community with real-world creative practice. We believe that it is only by working closely together ? as musicians, researchers, and technology enthusiasts ? that we can significantly advance our respective fields to achieve better expression, creative innovation, and human connection through music in ways that are currently beyond our imagination. And in what better place in the world could we extend such bridges than in the San Francisco Bay Area?
        <br><br>
        Relevant topics for ISMIR 2024 include, but are not limited to:
        <br>
        </v-card-text>

        <ul class="dot-list">
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
          <li>MIR fundamentals and methodology: music signal processing; symbolic music processing.</li>
        </ul>
        <br>
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Selection Process</v-card-title>
        <v-card-title class="text-h5" style="color: grey;">Full Paper Review:</v-card-title>
        <v-card-text class="text-body">
          Each paper will be reviewed by at least three reviewers and a program committee member (meta-reviewer) who will oversee the process and write a meta-review with a recommendation. The Scientific Program Chairs will make the final decision based on that recommendation. Double blind review: ISMIR follows a double-blind review process - violation may result in desk rejection. Authors should not know the names of the reviewers of their papers, and reviewers should not know the name(s) of the author(s).
        </v-card-text>
        <br>
        <v-card-title class="text-h5" style="color: grey;">Evaluation Criteria: </v-card-title>
        <v-card-text class="text-body">
          Evaluation criteria include scholarly/scientific quality, novelty of the paper, reusable insights, novelty, readability and paper organization, potential to generate discourse, and relevance of the topic to ISMIR. Papers which propose brave new ideas are valued. It is helpful to read the reviewer guidelines before paper submission.
        </v-card-text>
        <br>
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Submission Procedure</v-card-title>
        <v-card-text class="text-body">
          We will be using CMT for paper submissions: https://cmt3.research.microsoft.com/ISMIR2024
          <br><br>
          Abstract submission: The paper title, author names, contact details, subject areas, and abstract must be submitted and finalized on CMT by the abstract submission deadline. The title and abstract, together with the selected subject area, are the primary sources for assigning papers to reviewers. So, make sure that they form a concise and complete summary of your paper with sufficient information to let someone who has not read the full paper know what it is about.
          <br><br>
          Full paper submission: The full paper must be submitted by the full paper submission deadline. You may not modify any metadata on CMT uploaded for the abstract deadline. You may make minor modifications to your paper abstract compared to the version on CMT, but egregious content changes to the abstract (e.g. completely different topics) may result in desk rejection of your paper.
          <br><br>
          Supplementary material: For the anonymous review process, in addition to the PDF file of the manuscript, authors may upload supplementary files for their submission, such as audio samples, demonstration videos, self-contained html web pages, code, etc. Submission of PDF files as supplementary material is discouraged. The supplementary materials should comply with the requirements for the double-blind review process.
          <br><br>
          Subject area: When submitting the abstracts, authors will be required to choose from the subject areas given. The list of subject areas is the one listed above and it will be available on the submission site.
          <br><br>
          Presenting authors: At least one author of each accepted paper must register to the conference (either in person or virtual), before the deadline given for author registration (deadline will be announced shortly). Failure to register before the deadline (August 23) will result in automatic withdrawal of the paper from the conference proceedings and program.
          <br><br>
          Publication: Accepted papers will be published on the conference website and on an open access repository using a Creative Commons Attribution 4.0 International License (CC BY 4.0).
        </v-card-text>
        <br>
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Submission Requirements</v-card-title>
        <v-card-text class="text-body">
          Paper format: Papers must be formatted using the ISMIR 2024 templates (LaTeX/Overleaf or Word). Authors are required to submit their papers in PDF format. Submissions that manipulate the template (e.g., by decreasing margins or font sizes) may be rejected without review. All fonts need to be embedded within the PDF.
          <br><br>
          Paper length: Papers must conform to a 6+N format: 6 pages of scientific content (including figures and tables) and any number of additional pages that contain only references and an optional ethics statement (new in 2024, see below). Overlength papers or papers that do not conform to the required format will be rejected without review. Acknowledgements can be included in the optional pages for the camera ready submission.
          <br><br>
          Ethics statement (optional: new in 2024): With the goal of encouraging more discussion of ethical considerations in the ISMIR research community, authors may now choose to include an ethics statement section in their submissions in addition to the 6 page limit for scientific content. Ethics statements are optional, though they are encouraged especially for work related to MIR technology with the potential for broad social impact, such as recommendation or generation. A good ethics statement should candidly discuss both positive and negative ethical considerations of the work, including but not limited to (1) the potential societal implications of the work, (2) the ethical manner in which the work was conducted (especially for work with user studies), and (3) the prior societal context before the work. Any cultural, economic, or broader societal risks posed by the work should be mentioned (though not necessarily resolved). Ethics statements should be placed directly in the submission PDF and do not count against the 6 page limit for scientific content. Information regarding approval from the Institutional Review Board can also be presented in this section although the names of institutions should be redacted for blind review. In most cases ethics statements would be 0-2 paragraphs, and certainly not longer than a page.
          <br><br>
          Anonymity of authors: Do not put your names under the title. Avoid using phrases such as "our previous work" when referring to earlier publications by the authors. Remove information that may identify the authors in the acknowledgments (e.g., co-workers and grant IDs). Check supplementary material for information that may reveal the authors' identity. Avoid providing links to websites that identify the authors. Anonymized materials may be uploaded as "Supplementary material".
          <br><br>
          Preprints: To maintain the legitimacy for our double-blind review process, we strongly discourage authors from posting near duplicate manuscripts on public archives (technical reports, arXiv, etc.). In the same spirit, to protect our double-blind review process, authors need to make sure they do not promote their work in any way during the review process (social media, blog, mailing-list, etc.), since this may prevent preserving anonymity.
          <br><br>
          External Materials: If the paper promises to make the code, dataset, or other materials available after the acceptance, our research community relies on the research ethics of the authors to fulfill their promise.
        </v-card-text>
        <br>
       
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
import ImportantDateTable from './ImportantDateTable.vue';
export default {
  components: {
    ImportantDateTable,
  },
  data() {
    return {
      expandedPanels: [], // ���� Ȯ��� �г��� �����ϴ� �迭
      sections: [
        {
          items: [
            { content: 'LBD Submission Deadline', date: '2025.00.00.' }
          ],
        },
        
      ],
    };
  },
}
</script>

<style scoped>
.dot-list {
  list-style-type: disc; /* �۸Ӹ� ��ȣ ��Ÿ�� ���� */
  font-size: 18px;
  padding-left: 40px; /* �鿩���� �� ���� */
  line-height: 1.8;
}
.text-body {
  font-size: 18px; /* �ؽ�Ʈ ��Ʈ ũ�� ���� */
  line-height: 1.8; /* �������� ���� �� ���� ���� */
  white-space: normal; /* �ٹٲ��� ��� */
  overflow-wrap: break-word; /* �ܾ ���� ���� �ʵ��� �ܾ� ������ �ٹٲ� */
}
.v-card-title {
  white-space: normal; /* ���񿡼� �ٹٲ��� ��� */
  overflow-wrap: break-word; /* �ܾ �߸��� �ʵ��� �ٹٲ� */
}
</style>
